import React, { useEffect, useState } from "react";
import Framework from "../components/Framework";
import Gui from "../components/Gui";
import Layout from "../components/layout"
import {Loader_1, Loader_2, Loader_3 , Loader_4, Loader_5, ThankYou} from "../screens/Loader";

const MainFramework = () => {

  return (
    <Layout>
      <Loader_1 />
    </Layout>
  );
};

export default MainFramework;
